import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';


const Home = React.lazy(() =>
  import(/* webpackChunkName: "views" */ './pages/home')
);

const Privacy = React.lazy(() =>
  import(/* webpackChunkName: "views" */ './pages/privacy')
);

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<Privacy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
